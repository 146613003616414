import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import getExcerptOfBody from "../utils/getExcerpt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import Slices from "../components/slices"

export const query = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        name
        body {
          ... on PrismicPageDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                text
                richText
              }
            }
          }
          ... on PrismicPageDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
        }
      }
    }
  }
`

export default function Page({ location, data }) {
  const page = get(data, `prismicPage.data`)
  if (!page) return null

  return (
    <Layout location={location}>
      <SEO title={page.name} description={getExcerptOfBody(page.body)} />
      <Grid page>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <span>{page.name}</span>
        </Breadcrumbs>
        <Slices slices={page.body} />
      </Grid>
    </Layout>
  )
}
